exports.components = {
  "component---src-static-pages-endpoints-404-tsx": () => import("./../../../src/StaticPages/Endpoints/404.tsx" /* webpackChunkName: "component---src-static-pages-endpoints-404-tsx" */),
  "component---src-static-pages-endpoints-ai-about-tsx": () => import("./../../../src/StaticPages/Endpoints/ai-about.tsx" /* webpackChunkName: "component---src-static-pages-endpoints-ai-about-tsx" */),
  "component---src-static-pages-endpoints-ai-tsx": () => import("./../../../src/StaticPages/Endpoints/ai.tsx" /* webpackChunkName: "component---src-static-pages-endpoints-ai-tsx" */),
  "component---src-static-pages-endpoints-ai-vision-tsx": () => import("./../../../src/StaticPages/Endpoints/ai-vision.tsx" /* webpackChunkName: "component---src-static-pages-endpoints-ai-vision-tsx" */),
  "component---src-static-pages-endpoints-cg-bim-tsx": () => import("./../../../src/StaticPages/Endpoints/cg-bim.tsx" /* webpackChunkName: "component---src-static-pages-endpoints-cg-bim-tsx" */),
  "component---src-static-pages-endpoints-company-tsx": () => import("./../../../src/StaticPages/Endpoints/company.tsx" /* webpackChunkName: "component---src-static-pages-endpoints-company-tsx" */),
  "component---src-static-pages-endpoints-contact-tsx": () => import("./../../../src/StaticPages/Endpoints/contact.tsx" /* webpackChunkName: "component---src-static-pages-endpoints-contact-tsx" */),
  "component---src-static-pages-endpoints-data-tsx": () => import("./../../../src/StaticPages/Endpoints/data.tsx" /* webpackChunkName: "component---src-static-pages-endpoints-data-tsx" */),
  "component---src-static-pages-endpoints-index-tsx": () => import("./../../../src/StaticPages/Endpoints/index.tsx" /* webpackChunkName: "component---src-static-pages-endpoints-index-tsx" */),
  "component---src-static-pages-endpoints-mirrorworld-tsx": () => import("./../../../src/StaticPages/Endpoints/mirrorworld.tsx" /* webpackChunkName: "component---src-static-pages-endpoints-mirrorworld-tsx" */),
  "component---src-static-pages-endpoints-news-tsx": () => import("./../../../src/StaticPages/Endpoints/news.tsx" /* webpackChunkName: "component---src-static-pages-endpoints-news-tsx" */),
  "component---src-static-pages-endpoints-philosophy-tsx": () => import("./../../../src/StaticPages/Endpoints/philosophy.tsx" /* webpackChunkName: "component---src-static-pages-endpoints-philosophy-tsx" */),
  "component---src-static-pages-endpoints-privacypolicy-tsx": () => import("./../../../src/StaticPages/Endpoints/privacypolicy.tsx" /* webpackChunkName: "component---src-static-pages-endpoints-privacypolicy-tsx" */),
  "component---src-static-pages-endpoints-q-a-tsx": () => import("./../../../src/StaticPages/Endpoints/q-a.tsx" /* webpackChunkName: "component---src-static-pages-endpoints-q-a-tsx" */),
  "component---src-static-pages-endpoints-saa-s-tsx": () => import("./../../../src/StaticPages/Endpoints/SaaS.tsx" /* webpackChunkName: "component---src-static-pages-endpoints-saa-s-tsx" */),
  "component---src-static-pages-endpoints-sample-tsx": () => import("./../../../src/StaticPages/Endpoints/sample.tsx" /* webpackChunkName: "component---src-static-pages-endpoints-sample-tsx" */),
  "component---src-static-pages-endpoints-service-tsx": () => import("./../../../src/StaticPages/Endpoints/service.tsx" /* webpackChunkName: "component---src-static-pages-endpoints-service-tsx" */),
  "component---src-static-pages-endpoints-start-up-story-tsx": () => import("./../../../src/StaticPages/Endpoints/start-up-story.tsx" /* webpackChunkName: "component---src-static-pages-endpoints-start-up-story-tsx" */),
  "component---src-static-pages-endpoints-streaming-tsx": () => import("./../../../src/StaticPages/Endpoints/streaming.tsx" /* webpackChunkName: "component---src-static-pages-endpoints-streaming-tsx" */),
  "component---src-static-pages-endpoints-webapp-tsx": () => import("./../../../src/StaticPages/Endpoints/webapp.tsx" /* webpackChunkName: "component---src-static-pages-endpoints-webapp-tsx" */),
  "component---src-static-pages-endpoints-works-tsx": () => import("./../../../src/StaticPages/Endpoints/works.tsx" /* webpackChunkName: "component---src-static-pages-endpoints-works-tsx" */)
}

